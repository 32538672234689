.bg-lp-darkest {
  background-color: rgb(111, 38, 61);
}

.bg-lp-dark {
  background: rgba(111, 38, 61, 0.74);
}

.bg-lp-light {
  background: rgba(111, 38, 61, 0.58);
}

.bg-lp-lightest {
  background: rgba(111, 38, 61, 0.25);
}

.space-top {
  padding-top: 8rem;
}

.space-bottom {
  padding-bottom: 8rem;
}

.dots-background {
  background-image: url(../src/images/dots-5.svg);
}

.referral-background {
  background-image: url(../src/images/cover-7.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.referral-card {
  background-color: rgba(111, 38, 61, 0.80);
}

.feature-card {
  font-size: 0.8rem;
}

.referral-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.referral-quote {
  font-size: 2.5rem;
  font-weight: 400;
  font-style: italic;
  color: rgba(111, 38, 61, 0.74);;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%238c8c8c' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%238c8c8c' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.telephone-link a{
  color: rgba(111, 38, 61, 0.74);
}

.text-responsive {
  font-size: calc(100% + 1vw + 1vh);
}

// Overides for Bootstrap Breakpoints 

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) { 
  .referral-background {
    height: 30rem;
  }
  .referral-card h5{
    font-size: small;
  }
  .referral-quote {
    font-size: 1.5rem;
  }

  .space-top {
    padding-top: 4rem;
  }
  .space-bottom {
    padding-bottom: 4rem;
  }

  .dots-card {
    width: 18rem;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) { 
  .referral-background {
    height: 30rem;
  }
  .referral-card h5{
    font-size: medium;
  }
  .referral-quote {
    font-size: 1.8rem;
  }

  .dots-card {
    width: 18rem;
  }

  .space-top {
    padding-top: 4rem;
  }
  .space-bottom {
    padding-bottom: 4rem;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
  .referral-background {
    height: 30rem;
  }
  .referral-quote {
    font-size: 2.0rem;
  }

  .feature-cards {
    font-size: small;
  }
  

  .dots-card {
    width: 18rem;
  }
  
  .space-top {
    padding-top: 6rem;
  }
  .space-bottom {
    padding-bottom: 6rem;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .referral-background {
    height: 40rem;
  }

  .feature-cards {
    font-size: medium;
  }

  .dots-card {
    width: 20rem;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .referral-background {
    height: 60rem;
  }

  .dots-card {
    width: 25rem;
  }

  .feature-cards {
    font-size: large;
  }

}